import React, { useEffect, useState } from 'react';
import './style.scss';
import {useHistory} from 'react-router-dom';
import { LanguageConsumer } from '../../../providers/LanguageProvider';
import { Link } from 'react-router-dom';

const LangToggle = (props) => {
   // const [frenchUrl, setFrenchUrl] = useState('/fr/');
   // const [englishUrl, setEnglishUrl] = useState('/en/');
    const [pathAfterLang, setPathAfterLang] = useState('');
    const history = useHistory();

    useEffect( () => {
        let splitPath = '/home';
    
        const path = history.location.pathname;
        if(path !== '/'){
            //console.log('more path ', history.location.pathname);
            // console.log('splitPath ',splitPath[1]);
            splitPath = path.split('/'+props.lang);
            setPathAfterLang(splitPath[1]);
          
        }else {
            //console.log('home route ',history.location.pathanme);
            setPathAfterLang(splitPath);
        }
        
    },[history.location.pathname, props.lang])

    //console.log('render ',props);
    return (

            <LanguageConsumer>
                {(context) => (
                    <div className="lang-toggle-wrap">
                        <div className="lang-toggle">
                            <Link onClick={context.updateLanguage} to={`/en${pathAfterLang}`} title="english" data-lang="en"  className={context.language === 'en' ? 'active': null}>En</Link>&nbsp;|&nbsp;
                            <Link onClick={context.updateLanguage} to={`/fr${pathAfterLang}`} title="francais" data-lang="fr" className={context.language === 'fr' ? 'active': null}>Fr</Link>
                        </div>
                    </div>
                
                )}
            </LanguageConsumer>

    )
}
export default LangToggle;


