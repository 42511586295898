export const tl_data = {
    "Open Now": {
        "en": "Open Now",
        "fr": "Ouvert maintenant"
    },
    "Open": {
        "en":"Open",
        "fr":"Ouvert"
    },
    "Closed": {
        "en": "Closed",
        "fr": "Fermé"
    },
    "Now": {
        "en": "Now",
        "fr": "maintenant"
    },
    "Get Directions": {
        "en": "Get Directions",
        "fr": "Obtenir des directions"
    },
    "Mobile ordering available": {
        "en": "Mobile ordering available ",
        "fr": "Commande mobile disponible "
    },
    "This location is currently closed": {
        "en": "This location is currently closed",
        "fr": "Ce lieu est actuellement fermé"
    },
    "Open Now until": {
        "en": "Open Now until",
        "fr": "Ouvert maintenant jusqu'à"
    }
}
