import React, { Component, Suspense } from 'react';
import '../../assets/scss/styles.scss';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import { LanguageContext } from '../../providers/LanguageProvider';
import { LocationContextProvider } from '../../context/LocationContext/LocationContext';
import { isDesktop } from '../../shared/helpers';
import ReactGA from 'react-ga';
import Loading from '../../components/Loading/Loading';
import Locations from '../../pages/Locations/Locations';
//import Locationz from '../../pages/Locationz/Locationz';
//import Locationz from '../../pages/Locations/Locations';
//import Home from '../../pages/Home/Home';
const Home = React.lazy(() => import('../../pages/Home/Home') );
//import ContactUs from '../../pages/ContactUs/ContactUs';
//import OurCommunity from '../../pages/OurCommunity/OurCommunity';
const OurCommunity = React.lazy(() => import('../../pages/OurCommunity/OurCommunity') );
//import BrewBar from '../../pages/BrewBar/BrewBar';
//const BrewBar = React.lazy(() => import('../../pages/BrewBar/BrewBar') );
//import Order from '../../pages/Order/Order';
const Order = React.lazy(() => import('../../pages/Order/Order') );
//import Terms from '../../pages/Terms/Terms';
//import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
//import Company from '../../pages/Company/Company';
//const CupCrew = React.lazy(() =>import('../../pages/CupCrew/CupCrew') );
//import CovidUpdate from '../../pages/CovidUpdate/CovidUpdate';
//import Faq from '../../pages/Faq/Faq';
const Faq = React.lazy(() => import ('../../pages/Faq/Faq'));
//import GiftCards from '../../pages/GiftCards/GiftCards';
//import GrassFedBeef from '../../pages/GrassFedBeef/GrassFedBeef';
//import DriveIn from '../../pages/DriveIn/DriveIn';

// Chunks
const OurMenu = React.lazy(() => import('../../pages/OurMenu/OurMenu'));
const OurMenuItem = React.lazy(() => import('../../pages/OurMenuItem/OurMenuItem'));
const OurValues = React.lazy(() => import('../../pages/OurValues/OurValues'));
const OurFood = React.lazy(() => import('../../pages/OurFood/OurFood') );
const OurPlanet = React.lazy(() => import('../../pages/OurPlanet/OurPlanet') );

// Single page Chunks
const ContactUs = React.lazy(() => import ('../../pages/ContactUs/ContactUs'));
const Terms = React.lazy(() => import ('../../pages/Terms/Terms'));
const PrivacyPolicy = React.lazy(() => import ('../../pages/PrivacyPolicy/PrivacyPolicy'));
const Company = React.lazy(() => import ('../../pages/Company/Company'));
const AppVerification = React.lazy(() => import ('../../pages/AppVerification/AppVerification'));
//const CupCrew  = React.lazy(() => import ('../../pages/CupCrew/CupCrew'));
const CovidUpdate  = React.lazy(() => import ('../../pages/CovidUpdate/CovidUpdate'));
const GiftCards = React.lazy(() => import ('../../pages/GiftCards/GiftCards'));
const TermsGiftCards = React.lazy(() => import ('../../pages/TermsGiftCards/TermsGiftCards'));
const GrassFedBeef = React.lazy(() => import ('../../pages/GrassFedBeef/GrassFedBeef'));
const DriveIn = React.lazy(() => import ('../../pages/DriveIn/DriveIn'));
const ZeroCup = React.lazy(() => import ('../../pages/ZeroCup/ZeroCup'));

// Google Analytics
const awId = "UA-40149682-8"; 
//const nuggetsId = "AW-861995182";
ReactGA.initialize(awId);

// ReactGA.initialize(
//     [
//       {
//         trackingId: awId,
//       }
//     ],
//     { debug: false }
//   );

ReactGA.pageview(window.location.pathname + window.location.search);
//ReactGA.pageview(window.location.pathname + window.location.search, ["tracker1", "tracker2"]);
//ReactGA.event({ category: "category", action: "action" }, ["tracker1", "tracker2"]);


class App extends Component {
    state = {
        isDesktop: isDesktop() ? true : false
    }
    
    // Grab the context!
    static contextType = LanguageContext;

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
       // loadCSS();
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        let viewPort = isDesktop();
        if (viewPort !== this.state.isDesktop){
            this.setState(prevState => ({
               isDesktop: viewPort
              }));
        }
    }
    render() {
        let lang = this.context.language;
        console.log ('final lang:', lang);
        return (
            <React.Fragment>
               
                    <Router>
                        <Switch>
                            {/* HOME */}
                            <Redirect from="/home" 
                                to={`/${lang}/home`} 
                            /> 
                          
                            {/* <Route exact path="/"  component={() => <Suspense fallback={<Loading />}><Home isDesktop={this.state.isDesktop} lang={lang} /></Suspense>} /> */}
                            {/* <Route exact path="/:lang(en|fr)"  component={() => <Suspense fallback={<Loading />}><Home isDesktop={this.state.isDesktop} lang={lang} /></Suspense>} />  */}
                            <Route path="/:lang(en|fr)/home" component={() => 
                                <Suspense fallback={<Loading />}>
                                    <Home isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            /> 
                            {/* OUR MENU */}
                            <Redirect from="/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)/:item" 
                                to={`/${lang}/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)/:item`} 
                            />
                            <Redirect from="/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)" 
                                to={`/${lang}/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)`} 
                            />
                            <Redirect from="/our-menu" 
                                to={`/${lang}/our-menu`} 
                            />
                            <Route  path="/:lang(en|fr)/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)/:item" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurMenuItem isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route path="/:lang(en|fr)/our-menu/:category(breakfast|burgers|chicken|sides|kidspack|drinks|brewbar)" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurMenu isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route  exact path="/:lang(en|fr)/our-menu" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurMenu isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />
                            
                            {/* LOCATIONS */}
                            <Redirect from="/locations/:locationId/:locationCity/:locationName" 
                                to={`/${lang}/locations/:locationId/:locationCity/:locationName`} 
                            />
                            <Redirect from="/locations" 
                                to={`/${lang}/locations`} 
                            />
                            <Route  exact path="/:lang(en|fr)/locations/:locationId/:locationCity/:locationName" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <LocationContextProvider>
                                        <Locations isDesktop={this.state.isDesktop} lang={lang} />
                                    </LocationContextProvider>
                                </Suspense>} 
                            />
                            <Route exact path="/:lang(en|fr)/locations" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <LocationContextProvider isDesktop={this.state.isDesktop} lang={lang}>
                                        <Locations isDesktop={this.state.isDesktop} lang={lang} />
                                    </LocationContextProvider>
                                </Suspense>} 
                            />

                            {/* OUR VALUES / OUR FOOD */}    
                            <Redirect from="/our-values/our-food/:category" 
                                to={`/${lang}/our-values/our-food/:category`} 
                            />
                            <Redirect from="/our-values/our-food" 
                                to={`/${lang}/our-values/our-food`} 
                            />
                            <Redirect from="/beyondmeat" 
                                to={`/${lang}/our-values/our-food/beyondmeat`} 
                            />
                            <Route exact path="/:lang(en|fr)/our-values/our-food/:category" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurFood isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Redirect from="/:lang(en|fr)/beyond-meat" 
                                to={`/${lang}/our-values/our-food/beyond-meat`} 
                            /> 
                            <Route exact path="/:lang(en|fr)/our-values/our-food" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurFood isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                           
                             {/* OUR VALUES / OUR FOOD */} 
                            <Redirect from="/our-values" 
                                to={`/${lang}/our-values`} 
                            />
                            <Route exact path="/:lang(en|fr)/our-values" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurValues isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />

                            {/* OUR VALUES / OUR PLANET */}
                            <Redirect from="/our-values/our-planet/:category" 
                                to={`/${lang}/our-values/our-planet/:category`} 
                            />
                            <Redirect from="/our-values/our-planet" 
                                to={`/${lang}/our-values/our-planet`} 
                            />
                            <Route exact path="/:lang(en|fr)/our-values/our-planet/:category" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurPlanet isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route exact path="/:lang(en|fr)/our-values/our-planet" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurPlanet isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />
                            
                            {/* OUR VALUES / OUR COMMUNITY */}
                            <Redirect from="/our-values/our-community/:category" 
                                to={`/${lang}/our-values/our-community/:category`} 
                            />
                            <Redirect from="/our-values/our-community" 
                                to={`/${lang}/our-values/our-community`} 
                            />              
                            <Redirect from="/:lang(en|fr)/our-values/our-community/mealshare" 
                                to={`/${lang}/our-values/our-community`} 
                            /> 
                            <Route exact path="/:lang(en|fr)/our-values/our-community/:category" component={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurCommunity isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />
                            <Route exact path="/:lang(en|fr)/our-values/our-community/" component={() => 
                                <Suspense fallback={<Loading />}>
                                    <OurCommunity isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                           
                            {/* <Route  exact path="/:lang/our-values/our-community" render={() => <Suspense fallback={<Loading />}><OurCommunity isDesktop={this.state.isDesktop} lang={lang} /></Suspense>} /> */}
                            {/* FAQ */}

                            <Redirect from="/faq/:category(beef|chicken|breakfast|beyond-meat|beverages|produce-and-more|environment|community|mobile-app)" 
                                to={`/${lang}/faq/:category(beef|chicken|breakfast|beyond-meat|beverages|produce-and-more|environment|community|mobile-app)`} 
                            />
                            <Redirect from="/faq" 
                                to={`/${lang}/faq`} 
                            />
                            <Route path="/:lang(en|fr)/faq/:category(beef|chicken|breakfast|beyond-meat|beverages|produce-and-more|environment|community|mobile-app)" component={() => 
                                <Suspense fallback={<Loading />}>
                                    <Faq isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route exact path="/:lang(en|fr)/faq"  render={() => 
                                <Suspense fallback={<Loading />}>
                                    <Faq isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />

                            {/* SINGLE PAGES */}
                            
                            {/* <Route  path="/:lang/contact-us"  component={() => <ContactUs isDesktop={this.state.isDesktop} lang={lang} />} />  */}
                            <Redirect from="/contact-us" 
                                to={`/${lang}/contact-us`} 
                            />
                            <Route  exact path="/:lang(en|fr)/contact-us" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <ContactUs isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Redirect from="/order" 
                                to={`/${lang}/order`} 
                            />
                            <Route  exact path="/:lang(en|fr)/order" component={() => 
                                <Suspense fallback={<Loading />}>
                                    <Order isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Redirect from="/brewbar" 
                                to={`/${lang}/our-menu/brewbar`} 
                            />
                            <Redirect from="/:lang(en|fr)/brewbar" 
                                to={`/${lang}/our-menu/brewbar`} 
                            />
                            
                            
                            {/* <Route  path="/:lang/terms"  component={() => <Terms isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Redirect from="/terms" 
                                to={`/${lang}/terms`} 
                            />
                            <Route  exact path="/:lang(en|fr)/terms" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <Terms isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />
                            <Redirect from="/terms-gift-cards" 
                                to={`/${lang}/terms-gift-cards`} 
                            />
                            <Route  exact path="/:lang(en|fr)/terms-gift-cards" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <TermsGiftCards isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />

                            {/* <Route  path="/:lang/privacy"  component={() => <PrivacyPolicy isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Redirect from="/privacy" 
                                to={`/${lang}/privacy`} 
                            />
                            <Route  exact path="/:lang(en|fr)/privacy" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <PrivacyPolicy isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />

                            {/* <Route  path="/:lang/company"  component={() => <Company isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Redirect from="/company" 
                                to={`/${lang}/company`} 
                            />
                            <Route  exact path="/:lang(en|fr)/company" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <Company isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />

                            {/* <Route  path="/:lang/update"  component={() => <CovidUpdate isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Redirect from="/update" 
                                to={`/${lang}/update`} 
                            />
                            <Route  exact path="/:lang(en|fr)/update" render={() => 
                            <Suspense fallback={<Loading />}>
                                <CovidUpdate isDesktop={this.state.isDesktop} lang={lang} />
                            </Suspense>} 
                            />

                            {/* <Route  exact path="/:lang/cupcrew" render={() => <Suspense fallback={<Loading />}><CupCrew isDesktop={this.state.isDesktop} lang={lang} /></Suspense>} /> */}

                            {/* <Route  exact path="/:lang(en|fr)/onecup"><Redirect to="/:lang(en|fr)/our-values/our-planet/one-cup" /> </Route> */}
                            <Redirect from="/:lang(en|fr)/onecup" 
                                to="/:lang(en|fr)/our-values/our-planet/one-cup" 
                            />
                            {/* <Route exact path='/:lang/cupcrew' component={() => { 
                                window.location.href = 'https://cupcrew.aw.ca/'; 
                                return null;
                            }}/> */}

                            <Route  exact path="/:lang(en|fr)/zerocup" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <ZeroCup isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />

                            {/* <Route  path="/:lang/gift-cards"  component={() => <GiftCards isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Redirect from="/gift-cards" 
                                to={`/${lang}/gift-cards`} 
                            />
                            <Route  exact path="/:lang(en|fr)/gift-cards" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <GiftCards isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />
                            
                            {/* <Route  path="/en/canadian-grass-fed-beef"  component={() => <GrassFedBeef isDesktop={this.state.isDesktop} lang={lang} />} />
                            <Route  path="/fr/boeuf-nourri-herbe"  component={() => <GrassFedBeef isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Route exact path="/en/canadian-grass-fed-beef" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <GrassFedBeef isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route exact path="/fr/boeuf-nourri-herbe" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <GrassFedBeef isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                            />

                            {/* <Route  path="/:lang/drivein"  component={() => <DriveIn isDesktop={this.state.isDesktop} lang={lang} />} /> */}
                            <Route exact path="/:lang(en|fr)/drivein" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <DriveIn isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>} 
                            />
                            <Route exact path="/:lang(en|fr)/appverification" render={() => 
                                <Suspense fallback={<Loading />}>
                                    <AppVerification isDesktop={this.state.isDesktop} lang={lang} />
                                </Suspense>}
                             />

                            {/* If no route found redirect to home page */}
                            <Redirect from="*" to={`/${lang}/home`} /> 
                        </Switch>
                    </Router>
               
           </React.Fragment>
        );
    }
}

export default App;