import React, { useRef, useEffect, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import pin from './i/map-location.png';
import selectedPin from './i/map-selected-pin-small.png';
import personImage from './i/geolocation.png';
// import {target } from '../../../shared/restaurants'; 
// import { closest } from 'dom-helpers';
import { slug } from '../../../shared/helpers';
import Loading from '../../../components/Loading/Loading';

const Map = (props) => {
    const [initMap, setInitMap] = useState(false);
    const history = useHistory();
    const mapRef = useRef();

    const massagePos = (p) => {
        return new window.google.maps.LatLng(p.lat, p.lng);
    };
    
   
 
    // Init Map useEffect
    useEffect(() => {
       // console.log('map-props ',props);
    
        if (window.google && window.google.maps){
        
            const map = new window.google.maps.Map(mapRef.current, {
                center: props.center,
                zoom: parseInt(props.zoom) 
            });


            

            map.addListener( 'tilesloaded', function(ev){
                
            setInitMap(true);
               //let mapB = map.getBounds();
               //console.log('tilesloaded ',mapB);

                props.targetList.map(location => {
               
                    let visibleMarker = new window.google.maps.Marker({
                        icon: markerImage,
                        position: null,
                        map: null,
                        zIndex: 888888
                    });  

                    let selectedMarker = new window.google.maps.Marker({
                        icon: selectedPin,
                        position: null,
                        map: null,
                        zIndex: 9999999
                    })

                    visibleMarker.addListener("click", (event) => {
                        // map.setZoom(8);
                        let url = location.restnum + '/' + location.city_name +'/'+ slug(location.address1);
                        //props.clickMarkerLocation(location)
                        //console.log('click props ',url);
                        history.push(`/${props.lang}/locations/${url}`)
                        // map.setCenter(visibleMarker.getPosition());
                    });
                    visibleMarker.addListener("mouseover", (event) => {
                        selectedMarker.setPosition(massagePos(location))
                        selectedMarker.setMap(map)
                        
                    });

                    visibleMarker.addListener("mouseout", (event) => {
                        selectedMarker.setMap(null)
                    });

                    if ( map.getBounds().contains(massagePos(location)) ){
                        //console.log('locat ',location );
                        visibleMarker.setPosition(massagePos(location))
                        visibleMarker.setMap(map)
                    }

                    return true
               })

            });

            // var bounds = map.getBounds();
            // console.log('map bounds ',map);
            // var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            // var sw = bounds.getSouthWest(); // LatLng of the south-west corder
            // var nw = new window.google.maps.LatLng(ne.lat(), sw.lng());
            // var se = new window.google.maps.LatLng(sw.lat(), ne.lng());

    
            // Show user postion
            new window.google.maps.Marker({ position: props.position, map: map, icon: {
                url: personImage,
                size: new window.google.maps.Size(70, 70),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(35, 35),
                scaleControl: true
            }});

           
         
            var markerImage = {
                url: pin,
                size: new window.google.maps.Size(14, 14),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(7, 7),
                scaleControl: true
            };   
           let selectedMarker = new window.google.maps.Marker({
                icon: markerImage,
                position: null,
                map: null,
                zIndex: 8888888
            });  
            
            let hoverMarker = new window.google.maps.Marker({
                icon: selectedPin,
                position: null,
                map: null,
                zIndex: 9999999
            })

            // Set current store
            if(props.position){
               
                
                if(props.closestStore){
                    selectedMarker.setPosition(massagePos(props.closestStore));
                    hoverMarker.setPosition(massagePos(props.closestStore))
                }else {
                    selectedMarker.setPosition(massagePos(props.position));
                }
                
                selectedMarker.setMap(map);
                hoverMarker.setMap(map);
            }


            // Set Single Location Marker
            if(props.singleLocation === true){
                //console.log('single position');
                hoverMarker.setPosition(massagePos(props.center))
            }
    
        }
        return () => {
            //console.log('clean up map');
        }

    }, [props.lang, history, props.closestStore, props.position, props.center, props.zoom, props.targetList, props.singleLocation]);



    return (
        <div className="map-panel">
            {!initMap && <Loading /> }
            <div className={`map-wrap`} ref={mapRef}>
                
            </div>
        </div>

    )
}

export default Map;