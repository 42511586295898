import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon/Icon';
import './style.scss';

class Drawer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            drawerShow: false,
            id: props.id || '',
            title: props.title || ''
        }
    }

    // check if menu item changes, if so, reset drawer to close
	componentDidUpdate(prevProps) {
		if ( this.props.itemName !== prevProps.itemName) {
			this.setState({
                drawerShow: false
            })
		}
	  }

      drawerToggle = () => {
        //console.log('toggle drawer');
        this.setState((prevState) => {
            return { drawerShow: !prevState.drawerShow };
        })
    }


    closeOnClick = () => {
        this.setState({
            drawerShow: false
        });
    }
    
    render() {
        let children;
        if (this.state.drawerShow) {
            children = this.props.menuType === 'faq' ? React.cloneElement( this.props.children, { onClick: this.closeOnClick } ) : this.props.children;
		} else {
			children = '';
		}
        return (
            <div id={this.state.id} className={`drawer  ${this.state.drawerShow ? 'open' : 'closed'}`}>
                
                <div className="title-wrap" onClick={this.drawerToggle}>
                    {this.props.title}
                    <div className="icon"><Icon type="plus-inverted"/></div>
                </div>
             
                <CSSTransition
                    in={this.state.drawerShow}
                    classNames="drawer-transition"
                    timeout={{ enter: 400, exit: 400 }}
                >
                    <div className=""> 
                        <hr className="first" />
                        {children}
                    </div>
                </CSSTransition>
               
            </div>
        )
    }
}
export default Drawer;