import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../Icon/Icon';
import { TranslatableText } from '../../../providers/LanguageProvider';

const DesktopMenu = (props) => {

    return (
        <div className="explore-nav-dt">

        <Link className={'btn-nav '+(props.exploreMenuCat === 'our-values' ? 'active' : '')} to={`/${props.lang}/our-values`}>
            <TranslatableText dictionary={{
                en: "Overview",
                fr: "Aperçu" }}>
            </TranslatableText>
        </Link>

        <div className="dropdown our-food">
            <button className={'dropbtn nav-btn '+(props.exploreMenuCat === 'our-food' ? 'active':'')}>
                <TranslatableText dictionary={{
                    en: "Our Food",
                    fr: "Nos ingrédients" }}>
                </TranslatableText><Icon className="keyboard_arrow_down" />
            </button>
            <div className="dropdown-content" id="myDropdown">
                <ul className="our-food">
                    <li>
                        <Link className={props.exploreMenuCat === 'our-food' && !props.activePage ? 'active' : ''} to={`/${props.lang}/our-values/our-food`}>
                            <TranslatableText dictionary={{
                                en: "Overview",
                                fr: "Aperçu" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li className="category">
                        <TranslatableText dictionary={{
                            en: "Protein",
                            fr: "Protéines" }}>
                        </TranslatableText>
                    </li>
                    <li>
                        <Link className={props.activePage === 'beef' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/beef`}>
                            <TranslatableText dictionary={{
                                en: "Beef",
                                fr: "Bœuf" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'chicken' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/chicken`}>
                            <TranslatableText dictionary={{
                                en: "Chicken",
                                fr: "Poulet" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'eggs' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/eggs`}>
                            <TranslatableText dictionary={{
                                en: "Eggs",
                                fr: "Œufs" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'pork' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/pork`}>
                            <TranslatableText dictionary={{
                                en: "Pork",
                                fr: "Porc" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'beyond-meat' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/beyond-meat`}> 
                            Beyond Meat
                            {props.lang === 'fr' && <sup className="menu-dagger">†</sup>}
                        </Link>
                    </li>
                    {/*<li><a className={this.state.activeLink === 'plant-based-nuggets' ? 'active plant-based-nuggets' : 'plant-based-nuggets'} href={lang.linkTo("our-values/our-food/plant-based-nuggets")}>Plant-Based <br />Nuggets</a></li>*/}
                </ul>
                <ul className="our-food">
                    <li>&nbsp;</li>
                    <li className="category">
                        <TranslatableText dictionary={{
                            en: "Produce & More",
                            fr: "Garnitures et accompagnements" }}>
                        </TranslatableText>
                    </li>
                    <li>
                        <Link className={props.activePage === 'tomatoes' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/tomatoes`}>
                            <TranslatableText dictionary={{
                                en: "Tomatoes",
                                fr: "Tomates" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'onions' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/onions`}>
                            <TranslatableText dictionary={{
                                en: "Onions",
                                fr: "Oignons" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'lettuce' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/lettuce`}>
                            <TranslatableText dictionary={{
                                en: "Lettuce",
                                fr: "Notre laitue" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'cheese' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/cheese`}>
                            <TranslatableText dictionary={{
                                en: "Cheese",
                                fr: "Fromage" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                </ul>
                <ul className="our-food">
                    <li>&nbsp;</li>
                    <li className="category">
                        <TranslatableText dictionary={{
                            en: "Drinks",
                            fr: "Boissons" }}>
                        </TranslatableText>
                    </li>
                    <li>
                        <Link className={props.activePage === 'rootbeer' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/rootbeer`}>
                            <TranslatableText dictionary={{
                                en: "Root Beer",
                                fr: "Root Beer" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'coffee' ? 'active' : ''} to={`/${props.lang}/our-values/our-food/coffee`}>
                            <TranslatableText dictionary={{
                                en: "Coffee",
                                fr: "Café" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                </ul>
                <Link className={'animal-welfare '+(props.activePage === 'animal-welfare' ? 'active' : '')} to={`/${props.lang}/our-values/our-food/animal-welfare`}>
                    <TranslatableText dictionary={{
                        en: "Animal Welfare",
                        fr: "Bien-être animal" }}>
                    </TranslatableText>
                </Link>
            </div>
        </div>
        <div className="dropdown our-planet">
            <button className={'dropbtn nav-btn '+(props.exploreMenuCat === 'our-planet' ? 'active':'')}  >
                <TranslatableText dictionary={{
                    en: "Our Planet",
                    fr: "Notre planète" }}>
                </TranslatableText><Icon className="keyboard_arrow_down" />
            </button>
            <div className="dropdown-content" id="myDropdown">
                <ul className="our-food">
                    <li>
                        <Link className={props.exploreMenuCat === 'our-planet' && !props.activePage ? 'active' : ''} to={`/${props.lang}/our-values/our-planet`}>
                            <TranslatableText dictionary={{
                                en: "Overview",
                                fr: "Aperçu" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'one-cup' ? 'active' : ''} to={`/${props.lang}/our-values/our-planet/one-cup`}>
                            <TranslatableText dictionary={{
                                en: "A&W One Cup™",
                                fr: "Le Verre vert™ A&W" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'second-harvest' ? 'active' : ''} to={`/${props.lang}/our-values/our-planet/second-harvest`}>
                            <TranslatableText dictionary={{
                                en: "Second Harvest",
                                fr: "Deuxième Récolte" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                 
                    {/* <li>
                        <Link className={props.activePage === 'food-donation' ? 'active' : ''} to={`/${props.lang}/our-values/our-planet/food-donation`}>
                            <TranslatableText dictionary={{
                                en: "Food Donation",
                                fr: "Dons de nourriture" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'resource-conservation' ? 'active' : ''} to={`/${props.lang}/our-values/our-planet/resource-conservation`}>
                            <TranslatableText dictionary={{
                                en: "Resource Conservation",
                                fr: "Conservation des ressources" }}>
                            </TranslatableText>
                        </Link>
                    </li> */}
                </ul>
            </div>
        </div> 
        <div className="dropdown our-community">
            <button className={'dropbtn nav-btn '+(props.exploreMenuCat === 'our-community' ? 'active':'')} >
                <TranslatableText dictionary={{
                    en: "Our Community",
                    fr: "Notre communauté" }}>
                </TranslatableText><Icon className="keyboard_arrow_down" />
            </button>
            <div className="dropdown-content" id="myDropdown">
                <ul className="our-community">
                    <li>
                        <Link className={props.exploreMenuCat === 'our-community' && !props.activePage ? 'active' : ''} to={`/${props.lang}/our-values/our-community`}>
                            <TranslatableText dictionary={{
                                en: "Overview",
                                fr: "Aperçu" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'burgerstobeatms' ? 'active' : ''} to={`/${props.lang}/our-values/our-community/burgerstobeatms`}>
                            <TranslatableText dictionary={{
                                en: "Burgers to beat MS",
                                fr: "Le Rendez-vous A&W pour stopper la SP" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    <li>
                        <Link className={props.activePage === 'magnusmode' ? 'active' : ''} to={`/${props.lang}/our-values/our-community/magnusmode`}>
                            <TranslatableText dictionary={{
                                en: "Magnusmode",
                                fr: "Magnusmode" }}>
                            </TranslatableText>
                        </Link>
                    </li>
                    {/* <li>
                        <Link className={props.activePage === 'mealshare' ? 'active' : ''} to={`/${props.lang}/our-values/our-community/mealshare`}>
                            <TranslatableText dictionary={{
                                en: "Mealshare",
                                fr: "Repas-partagé" }}>
                            </TranslatableText>
                        </Link>
                    </li> */}
                </ul>
            </div>
        </div>  

     </div>

    )
}
export default DesktopMenu;