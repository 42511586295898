import React, {useEffect, useState} from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';

const ExploreMenu = (props) => {

    const [activePage, setActivePage] = useState('');
    const params = useParams();

    useEffect(() => { 
        setActivePage(params.category);
        if(params.category === undefined || params.category === ''){
           // console.log('empty');
            setActivePage('our-values');
        }
    },[params])

    return(

        <div className="explore-menu-wrap">
            {props.isDesktop && <DesktopMenu {...props} activePage={activePage} />}
            {!props.isDesktop && <MobileMenu {...props} activePage={activePage} />}
        </div>

    )
}
export default ExploreMenu;