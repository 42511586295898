import React from 'react';
import './style.scss';
import Drawer from '../../Drawer/Drawer';

const MobileMenu = (props) => {
    console.log('active page mobile ',props.activePage);
    return (
        <Drawer title={props.lang !== 'fr' ? "Explore Our Values" : "Découvrir nos valeurs"} className="explore " >
            <ul className="list-items">
              <li ><a className={'overview '+(props.activePage === 'our-values' ? 'active' : '')} href={`/${props.lang}/our-values`}>{props.lang !== 'fr' ? 'Overview' : 'Aperçu'}</a></li>
               <li>
                <Drawer title={props.lang !== 'fr' ? "Our Food" : "Nos ingrédients"}>
                    <div className="options">
                        <a className={props.activePage === 'our-food' ? 'active' : ''} href={`/${props.lang}/our-values/our-food`}>{props.lang !== 'fr' ? 'Overview' : 'Aperçu'}</a>
                        <h3>{props.lang !== 'fr' ? "Proteins" : "Protéines"}</h3>
                        <a className={props.activePage === 'beef' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/beef`}>{props.lang !== 'fr' ? "Beef" : "Bœuf"}</a>
                        <a className={props.activePage === 'chicken' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/chicken`}>{props.lang !== 'fr' ? "Chicken" : "Poulet"}</a>
                        <a className={props.activePage === 'eggs' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/eggs`}>{props.lang !== 'fr' ? "Eggs" : "Œufs"}</a>                  
                        <a className={props.activePage === 'pork' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/pork`}>{props.lang !== 'fr' ? "Pork" : "Porc"}</a>
                        <a className={props.activePage === 'beyondmeat' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/beyond-meat`}>
                            {props.lang !== 'fr' ? 
                                <>
                                    Beyond Meat
                                </> 
                            :  
                                <>
                                    Beyond Meat<sup className="menu-dagger">†</sup>
                                </>
                            }
                        </a>
                        
                        <h3>{props.lang !== 'fr' ? "Produce & More" : "Garnitures et accompagnements"}</h3>
                        <a className={props.activePage === 'tomatoes' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/tomatoes`}>{props.lang !== 'fr' ? "Tomatoes" : "Tomates"}</a>
                        <a className={props.activePage === 'onions' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/onions`}>{props.lang !== 'fr' ? "Onions" : "Oignons"}</a>
                        <a className={props.activePage === 'lettuce' ? 'active' : ''} href={`${props.lang}/our-values/our-food/lettuce`}>{props.lang !== 'fr' ? "Lettuce" : "Laitue"}</a>
                        <a className={props.activePage === 'cheese' ? 'active' : ''} href={`${props.lang}/our-values/our-food/cheese`}>{props.lang !== 'fr' ? "Cheese" : "Fromage"}</a>
                        
                        <h3>{props.lang !== 'fr' ? "Drinks" : "Boissons"}</h3>
                        <a className={props.activePage === 'rootbeer' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/rootbeer`}>{props.lang !== 'fr' ? "Root beer" : "Root beer"}</a>
                        <a className={props.activePage === 'coffee' ? 'active' : ''} href={`/${props.lang}/our-values/our-food/coffee`}>{props.lang !== 'fr' ? "Coffee" : "Café"}</a>
                        <a className={'animal-welfare '+(props.activePage === 'animal-welfare' ? 'active' : '')} href={`${props.lang}/our-values/our-food/animal-welfare`}>{props.lang !== 'fr' ? "Animal Welfare" : "Bien-être animal"}</a>
                    </div>
                 </Drawer>
              </li>
                <li className="">
                <Drawer title={props.lang !== 'fr' ? 'Our Planet' : 'Notre planète'}>
                    <div className="options">
                        <a className={props.activePage === 'our-planet' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet`}>{props.lang !== 'fr' ? 'Overview' : 'Aperçu'}</a>
                        <a className={props.activePage === 'one-cup' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet/one-cup`}>{props.lang !== 'fr' ? 'A&W One Cup™' : 'Le Verre vert™ A&W'}</a>
                        <a className={props.activePage === 'second-harvest' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet/second-harvest`}>{props.lang !== 'fr' ? 'Second Harvest' : 'Deuxième Récolte'}</a>
                        {/* <a className={props.activePage === 'waste-reduction' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet/waste-reduction`}>{props.lang !== 'fr' ? 'Waste Reduction' : 'Réduction des déchets'}</a>
                        <a className={props.activePage === 'food-donation' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet/food-donation`}>{props.lang !== 'fr' ? 'Food Donation' : 'Dons de nourriture'}</a>
                        <a className={props.activePage === 'resource-conservation' ? 'active' : ''} href={`/${props.lang}/our-values/our-planet/resource-conservation`}>{props.lang !== 'fr' ? 'Resource Conservation' : 'Conservation des ressources'}</a> */}
                    </div>
                </Drawer>
              </li>
              <li className="">
                <Drawer title={props.lang !== 'fr' ? 'Our Community' : 'Notre communauté'}>
                    <div className="options">
                        <a className={props.activePage === 'our-community' ? 'active' : ''} href={`/${props.lang}/our-values/our-community `}>{props.lang !== 'fr' ? 'Overview' : 'Aperçu'}</a>
                        <a className={props.activePage === 'burgerstobeatms' ? 'active' : ''} href={`/${props.lang}/our-values/our-community/burgerstobeatms`}>{props.lang !== 'fr' ? 'Burgers to beat MS' : 'Le Rendez-vous A&W pour stopper la SP'}</a> 
                        <a className={props.activePage === 'magnusmode' ? 'active' : ''} href={`/${props.lang}/our-values/our-community/magnusmode`}>{props.lang !== 'fr' ? 'Magnusmode' : 'Magnusmode'}</a>
                        {/* <a className={props.activePage === 'mealshare' ? 'active' : ''} href={`/${props.lang}/our-values/our-community/mealshare`}>{props.lang !== 'fr' ? 'Mealshare' : 'Repas-partagé'}</a> */}
                    </div>
                 </Drawer> 
              </li>  

            </ul>
          </Drawer>

    )
}
export default MobileMenu;