import ReactGA from 'react-ga';


export const isDesktop = () => {
    var viewWidth = function() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    };
    return viewWidth() > 850;
}

export const getLang = () => {

    var language;
    const prefLang = navigator.language; // get preferred language from browser
    const langFr = 'fr'; // all french language codes
    //console.log('langFr: ', prefLang);


    if(prefLang) {
        language = prefLang.includes(langFr) ? 'fr' : 'en'; // if browser preference language is found and includes a french lang code, set it to fr
    } else {
        language = 'en'; // if prefLang doesnt exist, set to EN (not sure if browsers can have NO language preference but just in case)
    }
    console.log('lang url', language);
   
	// Load the language from the document location. this takes precedence if someone enters the url with hardcoded /en of /fr, regardless of users browser language preference
	var m = null;
    var checkLang;
    // eslint-disable-next-line
	if ((m = /^\/([a-z]{2})[\/.*]?/.exec(document.location.pathname)) !== null) {

        checkLang = m[1];

        switch (checkLang) {
            case "en":
                language = 'en';
                break;
            case "fr":
                language = 'fr';
                break;
            default:// do nothing;
            //language = (prefLang) ? language : 'en'; // NO LONGER NEEDED. this checked if preflang exists, if so, set it to the determined language on line 19. but this was redundant since the language is already set before it even gets here
            break;
        }

        // if(m[1] !== 'en' || m[1] !== 'fr') {
        //     language = (prefLang) ? language : 'en';
        //     console.log('in loop: ', language);
        // }
        // language = m[1];
        // //If is not EN or FR, hardcode to EN to ensure some text displays. 
        // if (language !== 'fr'){
        //     language = 'en'
        // }
	}
    return language;
}
  
export const tl = (translations, keystring, language = 'en') => {

    if (translations.hasOwnProperty(keystring)) {
        
        if (translations[keystring].hasOwnProperty(language) && translations[keystring][language])
            return translations[keystring][language];
        return translations[keystring]['en']; // Default to English, if we don't have a translation.
    } else {
        //console.log("No translation entry for:" + keystring);
        return keystring;
    }
    
}

export const titleUp = (str) => {
    str = str.replace(/-+/g, '-');
    str = str.replace(/_+/g, '_');
    str = str.replace(/-/g, ' ');
    str = str.replace(/_/g, ' ');
		return str.toLowerCase().split(' ').map(function(word) {
			return (word.charAt(0).toUpperCase() + word.slice(1));
		}).join(' ');
	}


export const api_location = window.location.href; // Relative

/**
	 * Convert time range strings.
	 */
export const timeRange = (str) => {
    var chunks = str.split("-");
    return this.time(chunks[0].trim()) + ' - ' + this.time(chunks[1].trim());
};
	
export const num = (str, lang = "en") => {
	  var number = (str + "").replace('.00', ''); // Strip .00s
		if (lang === 'fr') {
			return number.replace(',', '').replace('.', ',');
		}
		return number;
};

export const retry = (f, delay, count) => {
    if (!f()) {
        if (count < 1) return;
        setTimeout(retry(this, f, delay, count-1), delay);
    }
}

export const formatTrim = (item) => {
    return item.replace("(", "").replace(")", "").trim();
}

export const slug = (str, space = '-') => {
    // eslint-disable-next-line
    str = str.replace(/\&/g, '').toLowerCase();
    str = str.replace(/\s+/g, space);
    // eslint-disable-next-line
    str = str.replace(/[^0-9a-z\_\-]/g, '');
    str = str.replace(/-+/g, '-');
    // eslint-disable-next-line
    str = str.replace(/_+/g, '_');
    return str;
};

export const loadDynamicScript = (callback) => {
    const existingScript = document.getElementById('woobox-sdk');
    
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://woobox.com/js/plugins/woo.js'; // URL for the third-party library being loaded.
        script.id = 'woobox-sdk'; // e.g., googleMaps or stripe
        document.body.appendChild(script);
    
        script.onload = () => {
          if (callback) callback();
        };
      }
    
      if (existingScript && callback) callback();
};

export const loadDynamicScriptTag = (id, src, callback) => {
    const existingScript = document.getElementById(id);
    
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = src; // URL for the third-party library being loaded.
        script.id = id; // e.g., googleMaps or stripe
        if(id === 'map'){
            script.async = 'async';  
            script.defer = 'defer';  
        }
        document.body.appendChild(script);
    
        script.onload = () => {
          if (callback) callback();
        };
      }
    
      if (existingScript && callback) callback();
};

export const loadDynamicGeoIp = (callback) => {
    const existingScript = document.getElementById('geoip-js');
    
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'; // URL for the third-party library being loaded.
        script.id = 'geoip-js'; // e.g., googleMaps or stripe
        document.body.appendChild(script);
    
        script.onload = () => {
          if (callback) callback();
        };
      }
    
      if (existingScript && callback) callback();
};

// old A&W font from typography.com
// export const loadCSS = (callback) => {
//     const existingLink = document.getElementById('cloud-font');

//     if(!existingLink){
//         const link = document.createElement('link');
//         link.rel = "stylesheet";
//         link.type = "text/css";
//         link.href = 'https://cloud.typography.com/6164292/7817552/css/fonts.css';
//         link.id = 'cloud-font';
//         document.body.appendChild(link);

//         link.onload = () => {
//             if (callback) callback();
//         }
//     }
// }

export const loadSlickCSS = (callback) => {
    const existingLink = document.getElementById('slick-css');

    if(!existingLink){
        const link = document.createElement('link');
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
        link.id = 'slick-css';
        document.body.appendChild(link);

        const link2 = document.createElement('link');
        link2.rel = "stylesheet";
        link2.type = "text/css";
        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
        link2.id = 'slick-theme';
        document.body.appendChild(link2);

        link.onload = () => {
            if (callback) callback();
        }
    }
}


export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: {
          ...item,
          lat: parseFloat(item['latitude']),
          lng: parseFloat(item['longitude'])
      }
    };
  }, initialValue);
};


export const trackGAEvent = (category, action, label) => {
    console.log("trackGAEvent:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label
    })
}
// this.retry = function(f, delay, count) {
//     if (!f()) {
//         if (count < 1) return;
//         setTimeout(this.retry.bind(this, f, delay, count-1), delay);
//     }
// };


