import React, { useEffect, useRef  } from 'react';
import './style.scss';
import Icon from '../../../components/Icon/Icon';
import useToggle from '../../../hooks/Toggle/useToggle';
import { TranslatableText } from '../../../providers/LanguageProvider';


const SearchBar = (props) => {
    //console.log('search props',props);
    const searchRef = useRef();
    const[filterDrawerShow, toggleFilterDrawerShow] = useToggle(false);
    const {checkedIsOpen, checkedWithDriveThru, checkedBrewbarOption} = props;
    let classIsOpen = checkedIsOpen ? 'checked' : '';
    let classInputIsOpen = checkedIsOpen ? 'checked' : 'outline';
    let classWithDriveThru = checkedWithDriveThru ? 'checked' : '';
    let classInputWithDriveThru = checkedWithDriveThru ? 'checked' : 'outline';
    let classBrewbarOption = checkedBrewbarOption ? 'checked' : 'outline';


    useEffect( () => {
        if(checkedIsOpen === true || checkedWithDriveThru === true || checkedBrewbarOption === true )  {
            toggleFilterDrawerShow(true);
        }
      
    },[checkedBrewbarOption, checkedIsOpen, checkedWithDriveThru, toggleFilterDrawerShow]);

    
    return(
        <>
        <div className="search-block" ref={searchRef}>
            <div className="input-wrap">
                <Icon className="aw" type="compass-inverted"/>
                {/* <i class="aw  compass-inverted" title=""><span></span></i> */}
                {props.lang === 'en' && <input className="search" type="text" placeholder="City or postal code..." onChange={e => props.onChange(e)} />}
                {props.lang === 'fr' && <input className="search" type="text" placeholder="Une ville ou un code postal..." onChange={e => props.onChange(e)} />}
                <button type="submit" title="submit" className={`submit`}><i className="aw  gt-inverted" title=""><span></span></i></button>
            </div>
            <div className="filter-btn-wrap">
                <button className={`btn-filter ${filterDrawerShow ? 'active' : ''}`} onClick={toggleFilterDrawerShow}>
                    <TranslatableText dictionary={{
                        en: "Filter",
                        fr: "Filtres" }}>
                    </TranslatableText> <Icon type="plus-inverted" />
                </button>
            </div>
        </div>
                
                
        <div className={`restaurant-options ${filterDrawerShow ? 'open' : 'close'}`}>
            <p>
                <TranslatableText dictionary={{
                    en: "Only show restaurants",
                    fr: "Seulement montrer les restaurants:" }}>
                </TranslatableText>
            </p>
            
            <div className="input-wrap">
                <label className={classIsOpen} ><i className={`aw ${classInputIsOpen}`} title=""><span></span></i><input type="checkbox" value="on" onClick={props.toggleOpenNowOption} />
                    <TranslatableText dictionary={{
                        en: "Open Now",
                        fr: "Ouverts maintenant" }}>
                    </TranslatableText>
                </label>
                <label className={classWithDriveThru}><i className={`aw ${classInputWithDriveThru}`} title=""><span></span></i><input type="checkbox" value="on" onClick={props.toggleDriveThruOption} />
                    
                    <TranslatableText dictionary={{
                        en: "With Drive-Thru",
                        fr: "Avec Service au volant" }}>
                    </TranslatableText>
                </label>
                <label className={classBrewbarOption}><i className={`aw ${classBrewbarOption}`} title=""><span></span></i><input type="checkbox" value="on" onClick={props.toggleBrewbarOption} />
                    
                    <TranslatableText dictionary={{
                        en: "With Brew Bar",
                        fr: "Avec Le Brew Bar" }}>
                    </TranslatableText>
                </label>
            </div>
        </div>
        </>
    )
}

export default SearchBar;