export const tl_data = {
    "smile_copy1": {
        "en": "Deliciousness is a state of mind.",
        "fr": "Le bon goût, c’est tout ce qui compte."
    },
    "smile_copy2": {
        "en": "Fresh is the way of life.",
        "fr": "Prenez la voie de la fraîcheur."
    },
    "FAQ": {
        "en" : "FAQ",
        "fr" : "Foire aux questions"
    },
    "Contact Us": {
        "en" : "Contact Us",
        "fr" : "Contactez-nous"
    },
    "Gift Cards": {
        "en": "Gift Cards",
        "fr": "Cartes-cadeaux"
    },
    "Work with Us": {
        "en": "Work with Us",
        "fr": "Travailler avec nous"
    }
}