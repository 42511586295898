import React from 'react';
import './style.scss';
import enLogo from './i/logo.svg';
import frLogo from './i/logo-fr.svg';

const Logo = (props) => {
    let logoImg = props.lang === 'fr' ? frLogo : enLogo;

    return(

        <React.Fragment>
            <img src={logoImg} className="aw-logo" alt="A&W Logo" width="293" height="150" />
        </React.Fragment>

    )
}
export default Logo;