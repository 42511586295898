export const tl_data = {
    "Open": {
      "en":"Open",
      "fr":"Ouvert"
    },
    "Closed": {
        "en": "Closed",
        "fr": "Fermé"
    },
    "Open Now": {
      "en":"Open Now",
      "fr":"Ouvert maintenant"
    },
    "This location is currently closed": {
        "en": "This location is currently closed",
        "fr": "Ce lieu est actuellement fermé"
    },
    "Amenities": {
        "en": "Amenities",
        "fr": "Services"
    },
    "Open 24 hours":{
        "en": "Open 24 hours",
        "fr": "Ouvert 24 heures"
    },
    "Drive-Thru": {
        "en": "Drive-Thru",
        "fr": "Drive-Thru"
    },
    "Brew Bar": {
        "en": "Brew Bar",
        "fr": "Le Brew Bar"
    },
    "Hours of Operation": {
        "en": "Hours of Operation",
        "fr": "Heures d’ouverture"
    },
    "Contact": {
        "en": "Contact",
        "fr": "Coordonnées"
    },
    "Apply": {
        "en": "Apply",
        "fr": "Postuler"
    }
}
