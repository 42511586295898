import React from 'react';
import './style.scss';

const Icon = (props) => {

    let classes = ['aw', props.className];
    classes.push(props.type);
    
    return (

        <i className={classes.join(" ")} title={props.title}><span></span></i>

    )
}

export default Icon;
