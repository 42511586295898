import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import  LanguageProvider from './providers/LanguageProvider/index.js';
//import LangProvider from './context/LanguageContext/LanguageContext';

ReactDOM.render(
     <LanguageProvider><App /></LanguageProvider>,
   // <LangProvider><App /></LangProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
