import React from "react";
import "./styles.scss";

const HoursIcon = (props) => (
    <div className="hours-icon">
            <svg className="amenities-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.81 29.74" style={{ fill: props.fill }}><path d="M15.4,29.74a14.84,14.84,0,0,1-10.9-4.5A14.86,14.86,0,0,1,0,14.34a15.68,15.68,0,0,1,2.18-8A15.36,15.36,0,0,1,8.8.29c1-.4,1.66-.39,2.08.05a2,2,0,0,1,.37,1.85,12.49,12.49,0,0,0-.2,1.57c0,.52-.05,1-.05,1.58a12.93,12.93,0,0,0,3.9,9.5,12.93,12.93,0,0,0,9.5,3.9,11.93,11.93,0,0,0,2.2-.18,5,5,0,0,1,1.6-.07c.77,0,1.26.14,1.47.52a1.85,1.85,0,0,1-.12,1.63A15.59,15.59,0,0,1,24,27.21,14.86,14.86,0,0,1,15.4,29.74Z"/></svg>
    </div>
);
export default HoursIcon;


