import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import {is24hourDriveThru, isOnlyDriveThru, hasHours, isRestaurantOpen, distanceOut} from '../../../shared/restaurants';
import Amenities from '../List/Amenities/Amenities';
import Hours from '../List/Hours/Hours';
import Contact from '../List/Contact/Contact';
import Map from '../Map/Map';
import {tl_data} from './translations.js';
import { time } from '../List/Hours/Hours';
import { applicableHours } from '../../../shared/restaurants';

const SingleLocationPanel = (props) => {

    const getOperationHeadline = (location, lang) => {
        
        let open_str = '';
        if (isRestaurantOpen(location) && hasHours(location)) {
            open_str = tl_data['Open'][props.lang];
            var closing_at = applicableHours(location).split("-")[1];

            if (closing_at === '0'){
                //console.log('closing is 0');
                closing_at = closing_at.substring(1); 
            }
            let closingTime = time(closing_at, props.lang);
            open_str = <h4 className="status open">{tl_data['Open Now until'][props.lang]} {closingTime}</h4>;
            //console.log('closing_at ',closing_at);

        }else{
            open_str =  <h4 className="status closed">{tl_data['Closed'][props.lang]}</h4>;
            // If hasHours (they aren't blank)
            if(hasHours(location)){
                open_str = <h4 className="status closed">{tl_data['This location is currently closed'][props.lang]}</h4>;
            }
            
        }

        return open_str
        
    }

    let operationHeadline = getOperationHeadline(props.info, props.lang);
    let location = props.info;
    let driveThruIcon; 
    let distance = distanceOut(props.info.distance);
    let driveThru24hrs = is24hourDriveThru(props.info);
    let onlyDriveThru = isOnlyDriveThru(props.info);
    let status = hasHours(props.info) ? (isRestaurantOpen(props.info) ? tl_data['Open'][props.lang] : tl_data['Closed'][props.lang]) : "";

    if(driveThru24hrs && onlyDriveThru){
        driveThruIcon =  <Icon type="front-car" colour="white"background="brown"  backgroundHover="orange" />;
    }
    
    let secondAddress = props.info.address2.length > 0 && <p>{props.info.address2}</p>;
    let directions =  (location.lat || location.lng) ? `${location.latitude},${location.lng}&daddr=${props.info.address1}+${props.info.city_name},${props.info.postal_code} Canada` : 'no';
    
    // const history = useHistory();
   

    // const closePanel = () => {
    //     history.push(`/${props.lang}/locations`)
    // }

    return (

        <div className="single-location-panel">
            
            <div className="title-wrap">

                <Link to={`/${props.lang}/locations`} className="location-home">

                    <h4>
                        <Icon type="pin" colour="brown" hover="orange" />
                        {props.info.address1}
                        <span className="fade"></span>
                    </h4>
                    
                    <div className="details">
                        <span className="driveIcon">{driveThruIcon}</span>
                        <span className={"status " + status}>{status}</span> 
                        <span className="distance">{distance} km</span> 
                        <Icon type="plus-inverted open" colour="white" background="brown"  backgroundHover="orange" />
                    </div>
                </Link>
            </div>
            
           { !props.isDesktop && <Map {...props} />}

            <div className="location-info">
                {operationHeadline}
                {/* <h4>{status && status + ' ' + tl_data['Now'][props.lang]}</h4> */}
				<h5>{props.info.address1}, {props.info.city_name}</h5>
                {secondAddress}
			
                <a 
                    href={`https://www.google.com/maps?saddr=${directions}`}
                    className="get-directions" 
                    title="Get Directions"
                    aria-label="Get Directions" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >{tl_data['Get Directions'][props.lang]} <Icon type="up-right-arrow-inverted" />
                </a>
               
                <Link to={`/${props.lang}/order`} className="mobile-order">{tl_data['Mobile ordering available'][props.lang]} <Icon type="gt-inverted" /></Link>
                <hr />
                <Amenities location={location} lang={props.lang} />
                <hr />
                <Hours location={props.info} lang={props.lang} />
                <hr />    
                <Contact location={props.info} lang={props.lang} />
            </div>

        </div>

    )
}
export default SingleLocationPanel;