export const data = {
    "home" : {
        "en": "home",
        "fr": "fr home"
    },
    "Our Menu": {
        "en": "Our Menu",
        "fr": "Notre menu",
    },
    "Our Values": {
        "en": "Our Values",
        "fr": "Nos valeurs",
    },
    "Our App": {
        "en": "Our App",
        "fr": "Notre appli",
    },
    "Coupons": {
        "en": "coupons",
        "fr": "coupons",
    },
    "more": {
        "en": "more",
        "fr": "plus",
    }
}