import React from 'react';
import './style.scss';
import LangToggle from './LangToggle/LangToggle';
//import GetOurApp from './GetOurApp/GetOurApp';
//import OrderDelivery from './OrderDelivery/OrderDelivery'
import { NavLink } from 'react-router-dom';
import { tl } from '../../shared/helpers';
import { data }from './translations.js';

const OptionsHeader = (props) => {
    let title = tl(data, 'find_aw_near_you_mobile', props.lang); 
    let titleDesktop = tl(data, 'find_aw_near_you', props.lang); 

    
    return (
        
        <div className="location-bar">
            {/* <OrderDelivery {...props} /> */}
            <NavLink className="more" to={`/${props.lang}/locations`}  title={title}>
                {props.isDesktop ? titleDesktop : title}
            </NavLink>
            <LangToggle {...props} />
        </div>

    )
}
export default OptionsHeader;