import React from 'react';
import { NavLink } from 'react-router-dom';

import { trackGAEvent } from '../../shared/helpers';

import './style.scss';

import Icon from '../../components/Icon/Icon';
import deliveryIconEn from './i/icon-en.png';
import deliveryIconFr from './i/icon-fr.png';

const MobileOrderBanner = (props) => {

  return (
    <>
    <div className="mobile-order-banner">
      <div className="mobileapp-callout">
        {props.lang === 'en' ? 
        <>
          <img className="app-icon" src={deliveryIconEn} alt="A&W app icon" height="200" width="200" />
          <div className="mobileapp-copy">
            <h3>Hungry for mobile&nbsp;ordering?</h3>
            <p>Try the new A&W&nbsp;app.</p>
          </div>
        </>
        :
        <>
          <img className="app-icon" src={deliveryIconFr} alt="A&W app icon" height="200" width="200" />
          <div className="mobileapp-copy">
          <h3>Prenez vos commandes en&nbsp;main.</h3>
            <p>Essayez la nouvelle appli&nbsp;A&W.</p>
          </div>
        </>
        }
    
      </div>
    
      <div className="mobileapp-btns">

        {props.lang === 'en' ? 
          <>
            <NavLink className="order-btn" onClick={() => trackGAEvent("Footer Delivery", "Click", "Button")} to={`/${props.lang}/order`}  title="Download the app">
              Download the app&nbsp; <Icon type="gt-inverted" /> 
            </NavLink>
          {/* <a onClick={() => trackGAEvent("Footer Delivery", "Click", "Button")} className="order-btn" href="https://order.online/business/~185179/" target="_blank" rel="noreferrer">Download the app&nbsp; <Icon type="angled-arrow-inverted" /></a> */}
            {/* <a href="https://itunes.apple.com/ca/app/waterfront-station/id1236416092?mt=8" className="app-btn" title="" ><img src={appStore} height="176" width="540" alt="apple store download" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.myelane2_aw" className="app-btn google-btn" title="" ><img src={googleStore} height="176" width="540" alt="google play download" /></a> */}
          </>
          :
          <>
          <NavLink className="order-btn" onClick={() => trackGAEvent("Footer Delivery", "Click", "Button")} to={`/${props.lang}/order`}  title="Télécharger l’appli">
            Télécharger l’appli&nbsp; <Icon type="gt-inverted" /> 
          </NavLink>
          {/* <a onClick={() => trackGAEvent("Footer Delivery", "Click", "Button")} className="order-btn" href="https://order.online/business/~185179/" target="_blank" rel="noreferrer">Télécharger l’appli &nbsp;<Icon type="angled-arrow-inverted" /></a> */}
          {/* <a href="https://itunes.apple.com/ca/app/waterfront-station/id1236416092?mt=8"  className="app-btn" title="" ><img src={appStoreFR} height="176" width="540" alt="apple store download" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.myelane2_aw" className="app-btn google-btn" title="" ><img src={googleStoreFR} height="176" width="540" alt="google play download" /></a> */}
          </>
        }
      </div>
    </div>
    </>
  )
}
export default MobileOrderBanner;