import React from 'react';
import './style.scss';
import Icon from '../../Icon/Icon';
import { trackGAEvent } from '../../../shared/helpers';

const Social = () => (
    <div className="blockwrap ">
        <div className="social">
            <a href="https://www.facebook.com/AWCanada/" className="facebook" target="_blank" rel="noreferrer" title="A&W Canada Facebook" onClick={() => trackGAEvent("Footer", "Facebook", "Social")}><Icon type="facebook" /></a>
            <a href="https://twitter.com/AWCanada" className="twitter" target="_blank" rel="noreferrer" title="A&W Canada Twitter" onClick={() => trackGAEvent("Footer", "Twitter", "Social")}><Icon type="twitter" /></a>
            <a href="https://www.youtube.com/channel/UClE8FsYCwp_JPcDhsAuU3Dw" className="youtube" target="_blank" rel="noreferrer" title="A&W Canada Youtube" onClick={() => trackGAEvent("Footer", "YouTube", "Social")}><Icon type="youtube" /></a>
            <a href="https://www.instagram.com/awcanada/" className="instagram" target="_blank" rel="noreferrer" title="A&W Canada Instagram" onClick={() => trackGAEvent("Footer", "Instagram", "Social")}><Icon type="instagram" /></a>
            <a href="https://www.tiktok.com/@awcanada" className="tiktok" target="_blank" rel="noreferrer" title="A&W Canada TikTok" onClick={() => trackGAEvent("Footer", "TikTok", "Social")}><Icon type="tiktok" /></a>
        </div>
    </div>

)
export default Social;