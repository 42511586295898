import React from 'react';
import './style.scss'; 
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon/Icon';
import { TranslatableText } from '../../../providers/LanguageProvider';
import Logo from '../../Logo/Logo';
import { tl, trackGAEvent } from '../../../shared/helpers';
import { data }from './translations.js';


const NavMenu = (props) => {
    //console.log('nav props ',props )
    const urlPrefix = props.lang;
    let btnClass = props.moreMenuShow ? 'active' : '';

    return(

    <nav className="nav-menu">
        <ul>
            <li>
                <NavLink to={`/`} title={tl(data, 'home', props.lang)}>
                    <Logo {...props} />
                </NavLink>
            </li>
            <li>
                <NavLink to={`/${props.lang}/our-menu`} title={tl(data, 'Our Menu', props.lang)}>

                    {
                        props.isDesktop ?
                            <TranslatableText dictionary={{
                                en: "Our Menu",
                                fr: "Notre menu" }}>
                            </TranslatableText> 
                        :
                            <TranslatableText dictionary={{
                                    en: "Menu",
                                    fr: "Menu" }}>
                            </TranslatableText> 
                    }
                </NavLink>
            </li>
            <li>
                <NavLink to={`/${urlPrefix}/our-values`} title={tl(data, 'Our Values', props.lang)}>
                    {
                        props.isDesktop ?
                            <TranslatableText dictionary={{
                                    en: "Our Values",
                                    fr: "Nos valeurs" }}>
                            </TranslatableText> 
                        :
                            <TranslatableText dictionary={{
                                    en: "Values",
                                    fr: "   Valeurs" }}>
                            </TranslatableText> 
                    }
                </NavLink>
            </li>
            <li>
                <NavLink onClick={() => trackGAEvent("Header Navigation Delivery", "Click", "Button")} to={`/${props.lang}/order`}  title={tl(data, 'Our App', props.lang)}>
                    {
                        props.isDesktop ?
                            <TranslatableText dictionary={{
                                    en: "Our App",
                                    fr: "Notre appli" }}>
                            </TranslatableText> 
                        :
                            <TranslatableText dictionary={{
                                    en: "Our App",
                                    fr: "Notre appli" }}>
                            </TranslatableText> 
                    }
                    {/* <Icon type="angled-arrow-inverted" /> */}
                </NavLink>
            </li>
            <li className="drop-menu-toggle">
                <button onClick={props.toggleDropMenu} className="drop-menu-toggle" aria-label="dropdown menu icon">
                   
                        {
                            props.isDesktop ?
                                <TranslatableText dictionary={{
                                        en: "More",
                                        fr: "Plus" }}>
                                </TranslatableText> 
                            :
                                null
                        } 
                        <div className={`icon-menu-wrap ${btnClass}`} href="#"><span></span></div>
                    
                </button>
                   
            </li>
        </ul>
    </nav>

)}
export default NavMenu;

